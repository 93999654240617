import "./service.css";

const ServiceHome = ({ img, data, i }) => {
  return (
    <div
      className="service"
      style={{ flexDirection: i % 2 === 1 ? "row-reverse" : "" }}
    >
      <div className="image">
        <img src={img} alt="service" />
      </div>
      <div className="description">
        <h4>{data.title}</h4>
        <p>{data.description}</p>
        <div className="arrow_button">
          <i className="stmicon-arrow_forward" />
        </div>
      </div>
    </div>
  );
};

const ServiceServices = ({ img, title, description, icon }) => {
  return (
    <div className="service_services">
      <img src={img} alt="service" />
      <div className="default">
      <i className={icon} />
      <h2>{title}</h2>
      </div>
      <div className="hover">
      <h2>{title}</h2>
      <p>{description}</p>
      </div>
    </div>
  );
};

export { ServiceHome, ServiceServices };
