import { Footer, Header, GallerieSlider, Loader } from "src/Components";
import { Select } from "src/Components/BasicComponents";
import { useEffect, useRef } from "react";
import HotelDatepicker from "hotel-datepicker";
import "./room.css";
import { useParams } from "react-router-dom";
import API from "src/API";
import { useState } from "react";
import moment from "moment";
import { motion } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";

const Room = () => {
  const elm = useRef(null);
  const { roomId } = useParams();

  const [room, setRoom] = useState(null);
  const [nbAdulte, setNbAdulte] = useState(1);
  const [nbChildren, setNbChildren] = useState(0);
  const [startDate, setStartDate] = useState(
    moment(new Date(localStorage.getItem("starting_date"))).format("YYYY-MM-DD")
  );
  const [finishDate, setFinishDate] = useState(
    moment(new Date(localStorage.getItem("ending_date"))).format("YYYY-MM-DD")
  );
  const [availibility, setAvailibility] = useState(null);
  const [price, setPrice] = useState(null);
  // const [error, setError] = useState(null);

  const i18n = {
    selected: "Votre séjour:",
    night: "Nuit",
    nights: "Nuits",
    button: "Fermer",
    clearButton: "Clear",
    submitButton: "Submit",
    "checkin-disabled": "Check-in disabled",
    "checkout-disabled": "Check-out disabled",
    "day-names-short": ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"],
    "day-names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    "month-names-short": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    "month-names": [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    "error-more": "Date range should not be more than 1 night",
    "error-more-plural": "Date range should not be more than %d nights",
    "error-less": "Date range should not be less than 1 night",
    "error-less-plural": "Date range should not be less than %d nights",
    "info-more": "Veuillez sélectionner une plage de dates apratir de 1 nuit",
    "info-more-plural":
      "Veuillez sélectionner une plage de dates d'au moins %d nuits",
    "info-range": "Please select a date range between %d and %d nights",
    "info-range-equal": "Please select a date range of %d nights",
    "info-default": "Please select a date range",
  };

  useEffect(() => {
    API.get(`rooms/${roomId}`)
      .then((resp) => {
        setRoom(resp.data.room);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [roomId]);

  useEffect(() => {
    if (room) {
      window.fecha = require("fecha");
      var datepicker = new HotelDatepicker(elm.current, {
        format: "DD MMM YYYY",
        infoFormat: "DD MMM YYYY",
	disabledDates: ['2024-09-27']
      });
      datepicker.setValue(
        localStorage.getItem("starting_date") +
          "- " +
          localStorage.getItem("ending_date")
      );
      datepicker.onSelectRange = () => {
        console.log(datepicker.getValue());
        let start = datepicker.getValue().split(" - ")[0].split(" ");
        let s_day = start[0];
        let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
        let s_year = start[2];
        localStorage.setItem(
          "starting_date",
          s_month + "/" + s_day + "/" + s_year
        );

        let end = datepicker.getValue().split(" - ")[1].split(" ");
        let e_day = end[0];
        let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
        let e_year = end[2];
        localStorage.setItem(
          "ending_date",
          e_month + "/" + e_day + "/" + e_year
        );
        setStartDate(s_year + "-" + s_month + "-" + s_day);
        setFinishDate(e_year + "-" + e_month + "-" + e_day);
      };
    }
  }, [room, startDate, finishDate]);

  useEffect(() => {
    if (room)
      API.get(`rates/rooms/available/${room?.id}`).then((resp) => {
        setAvailibility(resp.data.available);
      });
  }, [room]);

  useEffect(() => {
    if (room)
      API.post(`rates/pricing`, {
        start_date: new Date(localStorage.getItem("starting_date")),
        end_date: new Date(localStorage.getItem("ending_date")),
        nb_adults: nbAdulte,
        nb_children: nbChildren,
        room_id: room?.id,
      })
        .then((resp) => {
          console.log(resp + "here");
          setPrice(resp.data.price);
        })
        .catch((e) => {});
  }, [nbAdulte, nbChildren, startDate, finishDate, room]);

  // const calcluate = () => {
  //   setError(null);
  //   if (availibility === false) {
  //     setError("Room Not Available");
  //     return;
  //   }
  //   if (nbAdulte === null) {
  //     setError("Number of guests is required");
  //     return;
  //   }
  //   if (nbChildren === null) {
  //     setError("Number of children is required");
  //     return;
  //   }

  //   setCalcLoading(true);

  //   API.post(`roomtype/pricing/calculate/`, {
  //     startDate: startDate,
  //     finishDate: finishDate,
  //     nbAdulte: nbAdulte,
  //     nbChildren :nbChildren,
  //     ratedetail_id: room?.rateDetail.id,
  //   })
  //     .then((resp) => {
  //       console.log(room?.rateDetail.id)
  //       setCalcLoading(false);
  //       setPrice(resp.data.data.price);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return room ? (
    <motion.div
      variants={pagesAnim}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="room_page"
    >
      <Header />
      <h1>{room?.description}</h1>

      {console.log(room.pictures)}
      <div className="info_icons">
        <i className="stmicon-guest" /> {room?.max_adults}
        <i className="stmicon-nipple" /> {room?.max_children}
        {room?.nb_queen !== 0 ? (
          <>
            <i className="stmicon-bed2" /> {room?.nb_queen}
          </>
        ) : (
          <></>
        )}
        <i className="stmicon-ruler" /> {room?.size}
      </div>
      <GallerieSlider
        imagesSlider={JSON.stringify(room?.pictures.map((e) => e.filename))}
      />
      <div className="info">
        <div className="check">
          <input className="room_input" type="text" ref={elm} />
          <div
            style={{
              zIndex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Select
              list={Array.from(
                { length: room?.max_adults /*+ room?.max_additional*/ },
                (_, i) => i+1
              )}
              name="Guests"
              val={nbAdulte}
              setVal={setNbAdulte}
            />
          </div>
          <Select
            list={Array.from({ length: room?.max_children + 1 }, (_, i) => i)}
            name="Childrens"
            val={nbChildren}
            setVal={setNbChildren}
          />
          {availibility !== null ? (
            availibility ? (
              <span className="available">Available</span>
            ) : (
              <span className="notavailable">
                Not available between these dates
              </span>
            )
          ) : (
            <></>
          )}

          {/* {availibility ? (
            <div className="full_button" onClick={() => calcluate()}>
              Calculate price {">"}
            </div>
          ) : (
            <></>
          )} */}

          {/* {error !== null && error !== "" ? (
            <div className="error">{error}</div>
          ) : (
            <></>
          )} */}
          {/* {calcLoading ? <div>Loading price...</div> : <></>} */}
          {price && availibility ? (
            <>
              <div className="price">
                {" "}
                <span>{price}DA </span> / Total
              </div>
              <div
                className="full_button"
                onClick={() => {
                  localStorage.setItem("nbAdult", nbAdulte);
                  localStorage.setItem("startDate", startDate);
                  localStorage.setItem("finishDate", finishDate);
                  localStorage.setItem("nbChildren", nbChildren);
                  localStorage.setItem("room", room?.id);
                  localStorage.setItem("roomType", room?.description);

                  if (parseFloat(price) > 0) window.location = "/checkout";
                }}
              >
                CheckOut {">"}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="details">
          <div className="one">
            {room.rateDetails[0].rate.services.map((e, i) => {
              return (
                <div className="element" key={i}>
                  {e.description}
                </div>
              );
            })}
          </div>
          <div className="two">
            <h3>Room details</h3>
            <div className="element">
              <span>Guests:</span>
              <span>{room?.max_adults}</span>
            </div>
            <div className="element">
              <span>Children:</span>
              <span>{room?.max_children}</span>
            </div>
            <div className="element">
              <span>Room size:</span>
              <span>{room?.size}m²</span>
            </div>
            <div className="element">
              <span>Room type:</span>
              <span>{room?.description}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  ) : (
    <Loader />
  );
};

export default Room;
