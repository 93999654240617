const sliderImageAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 3,
      type: "spring",
      delay: 1,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const imgAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};
const SliderTextAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 1.5,
      type: "spring",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const SliderButtonAnim = {
  hidden: {
    opacity: 0,
    x: "25%",
  },
  visible: {
    opacity: 1,
    x: "50%",
    transition: {
      duration: 2.4,
      delay: 0.8,
      type: "spring",
    },
  },
  exit: {
    opacity: 0,
    x: "75%",
    transition: {
      duration: 1,
    },
  },
};

const ListAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "spring",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};

const RoomAnimation = (i) => {
  return {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "spring",
        delay: i * 0.15,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
  };
};

const GallerieAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2.4,
      type: "spring",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 2,
    },
  },
};

const pagesAnim = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      type: "easeOut"
    },
  },
  exit: {
    opacity: 0,
  },
}

export {
  imgAnim,
  sliderImageAnim,
  SliderTextAnim,
  SliderButtonAnim,
  ListAnimation,
  RoomAnimation,
  GallerieAnimation,
  pagesAnim
};
