import "./gallerie.css";
import { motion, AnimatePresence } from "framer-motion";
import { pagesAnim, RoomAnimation } from "src/Utils/animation";
import { Footer, Header } from "src/Components";
import { useEffect, useState } from "react";
import API from "src/API";
import { UPLOAD_URL } from "src/constants";

const Gallerie = () => {
  const [active, setActive] = useState(1);
  const [roomsImages, setRoomsImages] = useState([]);
  const [conferenceImages, setConferenceImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [display, setDisplay] = useState({ all: 9, rooms: 9, conference: 9 });

  useEffect(() => {
    API.get("content/gallerieImage").then((resp) => {
      setAllImages(resp.data.gallerieImages);
      setRoomsImages(resp.data.gallerieImages.filter((e) => e.type !== "CONFERENCE"));
      setConferenceImages(resp.data.gallerieImages.filter((e) => e.type === "CONFERENCE"));
    });
  }, []);
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        variants={pagesAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="checkrooms"
      >
        <Header />
        <div className="galleriep">
          <div className="mini_navg">
            <span
              onClick={() => setActive(1)}
              className={active === 1 ? "active" : ""}
            >
              All photos
            </span>
            <span
              onClick={() => setActive(2)}
              className={active === 2 ? "active" : ""}
            >
              Chambres{" "}
            </span>
            <span
              onClick={() => setActive(3)}
              className={active === 3 ? "active" : ""}
            >
              Salles de Conferences{" "}
            </span>
          </div>
          {allImages.length !== 0 && (
            <div className="images_container">
              {allImages.map((e, i) => {
                return (
                  active === 1 &&
                  i < display.all && (
                    <motion.img
                      variants={RoomAnimation((i % 9) / 2)}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      key={i}
                      src={UPLOAD_URL[process.env.NODE_ENV] + "others/" + e.picture.filename}
                      className="picture"
                    />
                  )
                );
              })}
            </div>
          )}
          {roomsImages.length !== 0 && (
            <div className="images_container">
              {roomsImages.map((e, i) => {
                return (
                  active === 2 &&
                  i < display.rooms && (
                    <motion.img
                      variants={RoomAnimation((i % 9) / 2)}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      key={i}
                      src={UPLOAD_URL[process.env.NODE_ENV] + "others/" + e.picture.filename}
                      className="picture"
                    />
                  )
                );
              })}
            </div>
          )}
          {conferenceImages.length !== 0 && (
            <div className="images_container">
              {conferenceImages.map((e, i) => {
                return (
                  active === 3 &&
                  i < display.conference && (
                    <motion.img
                      variants={RoomAnimation((i % 9) / 2)}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      key={i}
                      src={UPLOAD_URL[process.env.NODE_ENV] + "others/" + e.picture.filename}
                      className="picture"
                    />
                  )
                );
              })}
            </div>
          )}
          {allImages.length > display.all && active === 1 && (
            <button
              onClick={() => {
                setDisplay((prev) => {
                  return { ...prev, all: prev.all + 6 };
                });
              }}
            >
              Show more
            </button>
          )}
          {roomsImages.length > display.rooms && active === 2 && (
            <button
              onClick={() => {
                setDisplay((prev) => {
                  return { ...prev, rooms: prev.rooms + 6 };
                });
              }}
            >
              Show more
            </button>
          )}
          {conferenceImages.length > display.conference && active === 3 && (
            <button
              onClick={() => {
                setDisplay((prev) => {
                  return { ...prev, conference: prev.conference + 6 };
                });
              }}
            >
              Show more
            </button>
          )}
          {/* {roomsImages.length && (
            <>
              <div className="images_container">
                {roomsImages.map((e, i) => {
                  return (
                    <img
                      key={i}
                      src={API_URL.development + "/uploads/" + e.filename}
                      className="picture"
                    />
                  );
                })}
                {conferenceImages.map((e, i) => {
                  return (
                    <img
                      key={i}
                      src={API_URL.development + "/uploads/" + e.filename}
                      className="picture"
                    />
                  );
                })}
              </div>
              <button>Show more</button>
            </>
          )} */}
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
};

export default Gallerie;
