const contactData = [
    {
        cName: "stmicon-phone-call",
        title: "Téléphone",
        time: "036 81 41 41\n036 81 41 42\n036 81 41 43\n036 81 41 44",
        color: "#3a3138",
        size: "42px"
    },
    {
        cName: "stmicon-fax",
        title: "fax",
        time: "036 81 41 00",
        color: "#3a3138",
        size: "42px"
    },
    {
        cName: "stmicon-email",
        title: "fax",
        time: "reservation@parkmallhotel.com",
        color: "#3a3138",
        size: "42px"
    },
    {
        cName: "stmicon-maps-and-flags",
        title: "Adresse",
        time: "Avenue de l’ALN, Sétif",
        color: "#3a3138",
        size: "42px"
    }
]

export default contactData;