import './error404.css';

const Error404 = () => {
  return (
    <div className="notfounderror">
      <div>404</div>
      <div>THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST.</div>
      <button onClick={()=>{
        window.location = "/"
      }}>BACK TO HOMEPAGE</button>
    </div>
  );
};

export default Error404;