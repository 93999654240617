const itemsData = [
  {
    cName: "stmicon-cultural",
    title: "La Grande Poste",
    time: "2 MINUTES DE MARCHE",
    color: "#fb2920",
    size: "45px"
  },
  {
    cName: "stmicon-photo1",
    title: "Ain Fouara",
    time: "5 MINUTES DE MARCHE",
    color: "#49a3b9",
    size: "34px"
  },
  {
    cName: "stmicon-museum",
    title: "La Maison de Culture",
    time: "2 MINUTES DE MARCHE",
    color: "#60e15a",
    size: "45px"
  },
  {
    cName: "stmicon-shopping",
    title: "Centre Commercial Park Mall",
    time: "ACCES DIRECT",
    color: "#f3c434",
    size: "45px"
  },
];

export default itemsData;
