import "./conference.css";
import { motion, AnimatePresence } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";
import { Header, Footer, Loader } from "src/Components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import API from "src/API";
import { API_URL } from "src/constants";
import { GallerieSlider } from "src/Components";
import DatePicker from "react-datepicker";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^[a-z]+$/i;
const phoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const timeRegex = /^([0-1]?[0-9]|2[0-3])$/;
const nbRegex = /^\d{1,3}$/;

const DevisForm = ({ setters, values }) => {
  const [errP, setErrP] = useState(false);
  const [errNf, setErrNf] = useState(false);
  const [errEm, setErrEm] = useState(false);
  const [errPh, setErrPh] = useState(false);
  const [errNb, setErrNb] = useState(true);

  return (
    <div className="devisform">
      <div className="container">
        <div className="left">
          <h3>Réservation Directe</h3>
          <div>
            <i className="stmicon-phone" /> <span>036 81 41 41(42)</span>
          </div>
          <div>
            <i className="stmicon-email" />{" "}
            <span>reservation@parkmallhotel.com</span>
          </div>
        </div>
        <div className="right">
          <form>
            <div className="input">
              <span>Prénom</span>
              <input
                placeholder="Prénom"
                value={values.firstname}
                onChange={(e) => {
                  if (/^[a-zA-Z]*$/.test(e.target.value))
                    setters.setFirstname(e.target.value);
                }}
                onBlur={() => {
                  setErrP(nameRegex.test(values.firstname));
                }}
                className={errP ? "" : "error"}
              />
            </div>
            <div className="input">
              <span>Nom de famille </span>
              <input
                placeholder="Nom de famille "
                value={values.lastname}
                onChange={(e) => {
                  setters.setLastname(e.target.value);
                }}
                onBlur={() => {
                  setErrNf(nameRegex.test(values.lastname));
                }}
                className={errNf ? "" : "error"}
              />
            </div>
            <div className="input">
              <span>Adresse e-mail</span>
              <input
                placeholder="Adresse e-mail"
                value={values.email}
                onChange={(e) => {
                  setters.setEmail(e.target.value);
                }}
                onBlur={() => {
                  setErrEm(emailRegex.test(values.email));
                }}
                className={errEm ? "" : "error"}
              />
            </div>
            <div className="input">
              <span>Téléphone </span>
              <input
                conference
                placeholder="Téléphone"
                value={values.phoneNum}
                onChange={(e) => {
                  if (
                    e.target.value.length < 16 ||
                    Number(e.target.value) === 0
                  )
                    setters.setPhoneNum(e.target.value);
                }}
                onBlur={() => {
                  setErrPh(phoneRegex.test(values.phoneNum));
                }}
                className={errPh ? "" : "error"}
              />
            </div>

            <div className="input">
              <span>Nombre d'invités</span>
              <input
                placeholder="57"
                value={values.nbPerssone}
                onChange={(e) => {
                  if (
                    (e.target.value.length < 4 &&
                      Number(e.target.value) &&
                      nbRegex.test(e.target.value)) ||
                    e.target.value === "" ||
                    Number(e.target.value) === 0
                  )
                    setters.setNbPerssone(e.target.value);
                }}
                onBlur={() => {
                  setErrNb(nbRegex.test(values.nbPersonne));
                }}
                className={!errNb ? "" : "error"}
              />
            </div>
            <div className="input">
              <span>Time of arrival</span>
              <input
                placeholder="14"
                value={values.arrivalTime}
                onChange={(e) => {
                  if (
                    (e.target.value.length < 3 &&
                      Number(e.target.value) &&
                      timeRegex.test(e.target.value)) ||
                    e.target.value === "" ||
                    Number(e.target.value) === 0
                  )
                    setters.setArrival(e.target.value);
                }}
              />
              <span className="desc">
                L’heure correspond au fuseau horaire Sétif
              </span>
            </div>
            <div className="input">
              <span>Birth Date</span>
              <DatePicker
                selected={values.birthDate}
                onChange={(date) => setters.setBirthdate(date)}
              />
            </div>
          </form>
          <div className="bookingdetails">
            <h3>Booking details</h3>
            <div className="detail">
              <span>Check-in</span>
              <div className="inl_input">
                <DatePicker
                  selected={values.startDate}
                  onChange={(date) => setters.setStartDate(date)}
                />

                <span>(7:00 - 8:30 )</span>
              </div>
            </div>
            <div className="detail">
              <span>Check-out</span>

              <div className="inl_input">
                <DatePicker
                  selected={values.finishDate}
                  onChange={(date) => setters.setFinishDate(date)}
                />
                <span>(23:00 - 24:00 )</span>
              </div>
            </div>
            <div className="detail">
              <span>Pets</span>
              <span>Les animaux ne sont pas acceptés.</span>
            </div>
          </div>
          <div className="yourreservation">
            <h3>Booking details</h3>
            <div className="head">
              <span className="rm">La salle de conference :</span>
              <span className="surface">Le menu :</span>
              <span className="nb_nights">Nombre de jours</span>
            </div>
            <div className="element">
              <span className="rm">{values.conference.name}</span>
              <span className="surface">{values.menu.name}</span>
              <span className="nb_nights">
                {Math.round(
                  (new Date(values.finishDate) - new Date(values.startDate)) /
                    (24 * 60 * 60 * 1000) +
                    1
                )}
              </span>
            </div>
          </div>
          <p>
            Your personal data will be used to support your experience
            throughout this website, to process your reservations
          </p>
        </div>
      </div>
    </div>
  );
};

const ErrorModal = ({ setErrorActive }) => {
  return (
    <div className="errormodal">
      <div className="wrapperblur">
        <div className="content">
          <h3>Something went wrong, Check your inputs.</h3>
          <div className="buttons">
            <div
              className="fullbutton cancel"
              onClick={() => {
                setErrorActive(false);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ActiveMenuModal = ({ setActiveMenu, currentMenu }) => {
  return (
    <div className="menumodal">
      <div className="wrapperblur">
        <div className="content">
          <h3>All {currentMenu.name} Menu Items</h3>

          <div className="plats">
            {currentMenu?.plats?.map((e, i) => {
              return (
                <div className="plat" key={i}>
                  <img
                    src={API_URL.development + "/uploads/" + e.filename}
                    alt="plat"
                  />
                  <span>{e.name}</span>
                </div>
              );
            })}
          </div>
          <div className="buttons">
            <div
              className="fullbutton cancel"
              onClick={() => {
                setActiveMenu(false);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListMenus = ({ setMenuRes, setStep }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [menus, setMenus] = useState([]);
  const [currentMenu, setCurrentMenu] = useState("");

  useEffect(() => {
    API.get("menu").then((resp) => {
      setMenus(resp.data.data);
    });
  }, []);

  return (
    <div className="listmenus">
      {activeMenu && (
        <ActiveMenuModal
          setActiveMenu={setActiveMenu}
          currentMenu={currentMenu}
        />
      )}
      <div className="title">
        <h2>Choose your Menu</h2>
      </div>
      <div className="content">
        <div className="all_menus">
          {menus.map((e, i) => {
            return (
              <div className="menu_item" key={i}>
                <img
                  src={API_URL.development + "/uploads/" + e.filename}
                  alt="menu"
                />
                <div
                  className="menu_right"
                  onClick={() => {
                    setMenuRes(e);
                    setStep(2);
                  }}
                />
                <div className="menu_bot">
                  <div>
                    <span>Menu Name</span>
                    <span>{e.name}</span>
                  </div>
                  <div>
                    <span>Menu Price</span>
                    <span>{e.price} DA</span>
                  </div>
                  <div>
                    <span
                      onClick={() => {
                        console.log(e);
                        setCurrentMenu(e);
                        setActiveMenu(true);
                      }}
                    >
                      Check Menu Items ...
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Conference = () => {
  const { confId } = useParams();
  const [conference, setConference] = useState(null);
  const [step, setStep] = useState(0);
  const [menu, setMenu] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [birthDate, setBirthdate] = useState(new Date(1990, 0, 17));
  const [nbPerssone, setNbPerssone] = useState("");
  const [arrivalTime, setArrival] = useState("");

  const [posted, setPosted] = useState(false);
  const [errorActive, setErrorActive] = useState(false);

  const post = () => {
    if (
      !nameRegex.test(firstname) ||
      !nameRegex.test(lastname) ||
      !emailRegex.test(email) ||
      !phoneRegex.test(phoneNum) ||
      !nbRegex.test(nbPerssone)
    ) {
      setErrorActive(true);
    } else
      API.post("salleReservation", {
        startDate: startDate,
        finishDate: finishDate,
        firstname: firstname,
        lastname: lastname,
        email: email,
        phoneNum: phoneNum,
        birthDate: birthDate,
        nbPersonne: nbPerssone,
        arrivalTime: arrivalTime,
        menus: [menu.id],
        salles: [confId],
      }).then(() => {
        setPosted(true);
      });
  };

  useEffect(() => {
    API.get("salle/" + confId).then((resp) => {
      setConference(resp.data.data);
    });
  }, [confId]);
  console.log(step);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        variants={pagesAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="conferenceid"
      >
        <Header />
        {errorActive && <ErrorModal setErrorActive={setErrorActive} />}
        <div className="content">
          {conference ? (
            <>
              {step === 0 && (
                <div className="conference_container">
                  <h2>Book Your Conference Room Now !</h2>
                  {/* <img
                src={API_URL.development + "/uploads/" + conference.filename}
                alt="conference room"
              /> */}
                  <GallerieSlider
                    imagesSlider={JSON.stringify(conference.images)}
                  />
                  <div className="details">
                    <h3>Conference Room details</h3>
                    <div className="elements">
                      <div className="element">
                        <span>Name:</span>
                        <span>{conference.name}</span>
                      </div>
                      <div className="element">
                        <span>Price:</span>
                        <span>Starting from {conference.tarif} DA</span>
                      </div>
                      <div className="element">
                        <span>Size:</span>
                        <span>{conference.surface} m²</span>
                      </div>
                      <div className="element">
                        <span>Height:</span>
                        <span>{conference.height} m</span>
                      </div>
                      <div className="element">
                        <span>Length:</span>
                        <span>{conference.dimension1} m</span>
                      </div>
                      <div className="element">
                        <span>Width:</span>
                        <span>{conference.dimension2} m</span>
                      </div>
                    </div>
                    <h4>Max Number Of People On:</h4>
                    <div className="elements">
                      <div className="element">
                        <span>Theatre mode</span>
                        <span>{conference.nbTheatre}</span>
                      </div>
                      <div className="element">
                        <span>Class Room mode:</span>
                        <span>{conference.nbSalleClasse}</span>
                      </div>
                      <div className="element">
                        <span>Conference mode</span>
                        <span>{conference.nbConference}</span>
                      </div>
                      <div className="element">
                        <span>Banquet mode:</span>
                        <span>{conference.nbBanquet}</span>
                      </div>
                      <div className="element">
                        <span>U shape mode</span>
                        <span>{conference.nbU}</span>
                      </div>
                      <div className="element">
                        <span>Reception mode:</span>
                        <span>{conference.nbReception}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Book Now
                  </button>
                </div>
              )}
              {step === 1 && (
                <ListMenus setStep={setStep} setMenuRes={setMenu} />
              )}
              {step === 2 && (
                <DevisForm
                  setters={{
                    setArrival,
                    setBirthdate,
                    setEmail,
                    setPhoneNum,
                    setFirstname,
                    setLastname,
                    setNbPerssone,
                    setStartDate,
                    setFinishDate,
                  }}
                  values={{
                    arrivalTime,
                    birthDate,
                    email,
                    phoneNum,
                    firstname,
                    lastname,
                    nbPerssone,
                    startDate,
                    finishDate,
                    menu,
                    conference,
                  }}
                />
              )}
              {step >= 1 &&
                (!posted ? (
                  <div className="buttons">
                    <button onClick={() => setStep((prev) => prev - 1)}>
                      {" "}
                      {"<"} Prev
                    </button>
                    {step === 2 && (
                      <>
                        <button
                          onClick={() => {
                            post();
                          }}
                        >
                          Réserver maintenant
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <h5>
                    Your reservation is all set, You will hear from us as soon
                    as possible !
                  </h5>
                ))}
            </>
          ) : (
            <Loader />
          )}
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
};

export default Conference;
