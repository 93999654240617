import "./checkboxselection.css";

const CheckBoxSelection = ({
  title,
  filters,
  setTypeQuery,
  typeQuery,
  nbChildrensQuery,
  nbAdultesQuery,
  setNbChildrensQuery,
  setNbAdultesQuery,
}) => {
  return (
    <div className="checkboxselection">
      <span className="title"> {title} </span>
      {filters.map((e, i) => {
        return (
          <div className="checkboxarea" key={i}>
            <input
              type="checkbox"
              checked={
                typeQuery ? (
                  typeQuery[e.name]
                ) : nbChildrensQuery ? (
                  nbChildrensQuery == i + 1
                ) : nbAdultesQuery ? (
                  nbAdultesQuery == i + 1
                ) : (
                  <></>
                )
              }
              onChange={() => {
                if (typeQuery) {
                  if (e.name === "Delux") {
                    setTypeQuery({ ...typeQuery, Delux: !typeQuery.Delux });
                  }
                  if (e.name === "Standard") {
                    setTypeQuery({
                      ...typeQuery,
                      Standard: !typeQuery.Standard,
                    });
                  }
                  if (e.name === "Suite") {
                    setTypeQuery({ ...typeQuery, Suite: !typeQuery.Suite });
                  }
                }
                if (nbChildrensQuery) setNbChildrensQuery(i + 1);
                if (nbAdultesQuery) setNbAdultesQuery(i + 1);
              }}
              // onChange={() => {
              //   typeQuery ? (
              //     setTypeQuery((prev) =>
              //       e.name === "Delux"
              //         ? { ...typeQuery, delux: !prev.delux }
              //         : e.name === "Standard"
              //         ? { ...typeQuery, standard: !prev.standard }
              //         : e.name === "Suite"
              //         ? { ...typeQuery, suite: !prev.suite }
              //         : {}
              //     )
              //   ) : (
              //     <></>
              //   );

              //   e.stateFunc((prev) => {
              //     console.log(e);
              //     nbChildrensQuery ? (
              //       setNbChildrensQuery(parseInt(e.name))
              //     ) : (
              //       <></>
              //     );
              //     nbAdultesQuery ? setNbAdultesQuery(parseInt(e.name)) : <></>;

              //     return typeQuery ? (
              //       !prev
              //     ) : nbChildrensQuery ? (
              //       nbChildrensQuery === parseInt(e.name)
              //     ) : nbAdultesQuery ? (
              //       nbAdultesQuery === parseInt(e.name)
              //     ) : (
              //       <></>
              //     );
              //   });
              // }}
            />
            <span>{e.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CheckBoxSelection;
