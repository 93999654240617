import { useEffect, useState } from "react";
import "./slider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
// import sliderInfo from "src/Assets/Content/Slider";
import { Loader } from "..";
import { motion, AnimatePresence } from "framer-motion";
import {
  imgAnim,
  SliderButtonAnim,
  sliderImageAnim,
  SliderTextAnim,
} from "src/Utils/animation";
import API from "src/API";
import { UPLOAD_URL } from "src/constants";

const Slider = () => {
  const [activeSlide, SetActiveSlide] = useState(1);
  const [offsetY, setOffsetY] = useState(0);

  const [sliderImages, setSliderImages] = useState([]);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    API.get("content/slide").then((resp) => {
      console.log(resp.data)
      setSliderImages(resp.data.slides);
    });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const changeSlide = (i) => {
    if (activeSlide + i === 0) SetActiveSlide(sliderImages.length);
    else if (activeSlide + i === sliderImages.length + 1) SetActiveSlide(1);
    else SetActiveSlide(activeSlide + i);
    console.log("executed", activeSlide);
  };

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item, index) => {
  //     images[item.replace("./", "")] = r(item);
  //     return "";
  //   });
  //   return images;
  // }

  // const images = importAll(
  //   require.context("../../Assets/Images/Slider", false, /\.(png|jpe?g|svg)$/)
  // );

  // const images_names = Object.keys(images);
  // const title_name = images_names.map((e) =>
  //   e.replace(".jpg", "").replace("png", "")
  // );

  return (
    <div className="slider">
      {sliderImages.length !== 0 ? (
        <>
          {sliderImages.map((e, i) => (
            <AnimatePresence key={i}>
              {i + 1 === activeSlide && (
                <motion.div
                  key={i}
                  className="slider_content"
                  variants={sliderImageAnim}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <div className="slider_image">
                    <motion.img
                      variants={imgAnim}
                      initial="hidden"
                      animate="visible"
                      key={i}
                      src={UPLOAD_URL[process.env.NODE_ENV] +"others/" + e.picture.filename}
                      alt="Slider"
                      style={{
                        transform: `translateY(${
                          offsetY * 0.2
                        }px) scale(1, 1.34)`,
                      }}
                    />
                  </div>
                  <div className="slider_text">
                    <motion.h3
                    variants={SliderTextAnim}
                      initial="hidden"
                      animate="visible"
                      exit="exit">{e.title}</motion.h3>
                    <motion.h5
                     variants={SliderTextAnim}
                      initial="hidden"
                      animate="visible"
                      exit="exit">{e.description}</motion.h5>
                  </div>
                  {e.button_content ? (
                    <motion.button
                      className="slider_button"
                      variants={SliderButtonAnim}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      onClick={() => {
                        window.location = e.button_link;
                      }}
                    >
                      {e.button_content}
                    </motion.button>
                  ) : (
                    <></>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          ))}
          <div className="angle_right">
            <FontAwesomeIcon
              icon={faAngleRight}
              className="ar"
              onClick={() => {
                changeSlide(1);
              }}
            />
          </div>
          <div className="angle_left">
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="ar"
              onClick={() => {
                changeSlide(-1);
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Slider;
