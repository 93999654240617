import { Footer, Gomap, Header } from "src/Components";
import "./contact.css";
import { Item } from "src/Components/BasicComponents";
import { contactData } from "src/Assets/Content";
import { motion } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";

const Contact = () => {
  return (
    <motion.div
      variants={pagesAnim}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="contact"
    >
      <Header />

      <h3>ON EST LA POUR VOUS</h3>
      <p>
        Pour tous vos événements, vos reunions, vos fêtes et vos projets, le
        service commercial est a votre disposition
      </p>

      <div className="info">
        <div className="left">
          <h3>Réservation Directe</h3>

          <div className="items">
            {contactData.map((e, i) => {
              return <Item {...e} key={i} />;
            })}
          </div>
        </div>
        <div className="right">
          <Gomap />
        </div>
      </div>

      <form>
        <i className="stmicon-haircut" />
        <h3>Envoyez nous un message</h3>

        <div className="inpts">
          <input type="text" placeholder="Nom" />
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Sujet" />
          <input type="text" placeholder="Téléphone" />
          <textarea type="text" rows={6} placeholder="Message" />
        </div>

        <div className="full_button">Envoyer le message</div>
      </form>
      <Footer />
    </motion.div>
  );
};

export default Contact;
