import { useSearchParams } from "react-router-dom";
import "./paymentcheck.css";
import { ColorRing } from "react-loader-spinner";
import { useEffect } from "react";
import API from "src/API";
import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import Printer from "src/Assets/Icons/printer.png";
import Download from "src/Assets/Icons/download.png";
import Email from "src/Assets/Icons/email.png";

import Satim from "src/Assets/Images/satim.png";
import Logo from "src/Assets/Images/Logo.png";
import { useRef } from "react";

const Modal = ({ closeModal }) => {
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the email address to the server or perform some other action
    console.log(`Submitting email address: ${email}`);
    
    API.post("emails/reciept/" + searchParams.get("orderId"), {
      third_party_email: email,
    })
      .then((resp) => {
        console.log(resp);
        closeModal();
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="checkmodalemail">
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          placeholder="welcome@parkmallhotel.com"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <button type="submit">Send</button>
      </form>
      <button onClick={closeModal}>Close</button>
    </div>
  );
};


const PaymentCheck = () => {
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [resp, setResp] = useState({});
  const ref = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    API.get(`/reservations/status/${searchParams.get("orderId")}`)
      .then((resp) => {
        setResp(resp.data.info);
        setLoader(false);
      })
      .catch((e) => {
        window.location = `/payment/failure?order_id=${searchParams.get(
          "orderId"
        )}`;
      });
  }, []);

  const printDocument = () => {
    const input = ref.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(Logo, "PNG", 85, 15, 50, 50);
      pdf.text("Votre réservation a été confirmée", 65, 85);
      pdf.addImage(imgData, "PNG", 55, 100);
      pdf.save("download.pdf");
    });
  };

  const sendEmail = () => {
    API.get("emails/reciept/" + searchParams.get("orderId"))
      .then((resp) => {})
      .catch((e) => console.log(e));
  };

  return (
    <>
      {loader ? (
        <div className="paymentcheck">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#ffac41", "#ffac41", "#ffac41", "#ffac41", "#ffac41"]}
          />

          <h1>Checking payment status</h1>
        </div>
      ) : (
        <div className="paymentsuccess">
          <div className="successmessage">
            <div className="circle">
              <div className="checkicon" />
            </div>
            <img
              src={Logo}
              alt="parkmall hotel logo"
              className="reciept_logo"
            />
            <h3>Votre réservation a été confirmée !</h3>
          </div>

          <div className="order_confirmed_reciept" ref={ref}>
            <div className="element">
              <span>Responce </span> <span>{resp?.resp_code_desc}</span>
            </div>
            <div className="element">
              <span>Order Id</span> <span>{searchParams.get("orderId")}</span>
            </div>
            <div className="element">
              <span>Order Number</span>{" "}
              <span>{resp?.HotelReservation.order_number}</span>
            </div>
            <div className="element">
              <span>Approval Code</span> <span>{resp?.approval_code}</span>
            </div>
            <div className="element">
              <span>Transaction Date</span>{" "}
              <span> {new Date(resp?.created_at).toLocaleString()} </span>
            </div>
            <div className="element">
              <span>Deposit Amount</span>{" "}
              <span>{resp.amount / 100 + "DA"}</span>
            </div>
            <div className="element">
              <span>Paiment method</span> <span>carte CIB/EDAHABIA.</span>
            </div>
	      <div className="satim_call_us">
          <p>
            « en cas de problème , veuillez contacter le numéro vert de la satim
            »
          </p>
          <img src={Satim} alt="Satim contact info" />
        </div>
          </div>

          <div className="orderinformations">
            <img
              src={Download}
              alt="Telecharger Recu"
              onClick={() => printDocument()}
            />
            <span
              onClick={() => {
                printDocument();
              }}
            >
              {" "}
              Télécharger le reçu de paiement
            </span>
            <img
              src={Printer}
              alt="Imprimer Recu"
              onClick={() => window.print()}
            />
            <span onClick={() => window.print()}>
              {" "}
              Imprimer le reçu de paiement
            </span>
            <img
              src={Email}
              alt="Envoie de Recu par email"
              onClick={() => openModal()}
            />
            <span onClick={() => openModal()}>
              {" "}
              Envoyer le reçu de paiement par E-mail
            </span>
          </div>

	      <div className={isModalOpen ? "checkmodalemail-open" : ""}>
              {isModalOpen && <Modal closeModal={closeModal} />}
            </div>

          <div
            className="retour"
            onClick={() => {
              window.location = "/";
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default PaymentCheck;
