const feedbackData = [
  {
    stars: 5,
    content:
      "Gathered with friends over coffee, very warm and nice place. The place is very calming and the staff is amazing. We definatly enjoyed our evening, i would surley come back to try the pool and the food in brezza",
    clientname: "Shima jaafreh",
  },
  {
    stars: 5,
    content:
      "Très bon séjour avec un personnel au petit soin vraiment très agréable.(propreté ,services ,salle de sport 24/24 tt commodité) Rien a dire tout le monde est très attentif au besoin du client.",
    clientname: "Mohamed T",
  },
  {
    stars: 5,
    content:
      "Très bon hotel premier sejoure,surpris par le service la bouffe le calme et la propreté, rapport qualité prix excellent. Rien à dire. Je le recommande vivement et probablement j'y retournerai à mon prochain passage.",
    clientname: "HICSAI",
  },
];

export default feedbackData;