import "./conferences.css";
import { Header, Footer, Loader, Room } from "src/Components";
import { motion, AnimatePresence } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";
import { useEffect, useState } from "react";
import API from "src/API";
import { API_URL } from "src/constants";

const Conferences = () => {
  const [loading, setLoading] = useState(true);
  const [salles, setSalles] = useState([]);

  useEffect(() => {
    API.get("salle")
      .then((resp) => {
        setSalles(resp.data.data);
        console.log(resp.data.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        variants={pagesAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="conference"
      >
        <Header />
        <div className="content">
          {loading ? (
            <Loader />
          ) : (
            <>
              <h2>Book Your Conference Room Now !</h2>
              <p> Luxury </p>
              <div className="conferences">
                {salles?.map((e, i) => {
                  return (
                    <Room
                      id={e.id}
                      // img={`${API_URL[process.env.NODE_ENV]}/uploads/${
                      //   JSON.parse(rType.images)[0]
                      // }`}
                      img={API_URL.development + "/uploads/" + e.images[0]}
                      type={e.name}
                      price={e.tarif}
                      // delay={i}
                      // key={i}
                      size={e.surface}
                      maxAdults={e.nbTheatre}
                      maxChildrens={0}
                      nbLitsQueen={0}
                      isConference={true}
                      key={i}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
};

export default Conferences;
