import "./feedback.css";

//stars not dynamic since there is only 5 stars shown in the avis section
const FeedBack = ({ content, clientname }) => {
  return (
    <div className="feedback">
      <div className="feedback_content">{content}</div>
      <span>{clientname}</span>
    </div>
  );
};

export default FeedBack;
