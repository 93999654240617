import "./galleriesliderv2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import API from "src/API";
import { UPLOAD_URL } from "src/constants";

const GallerieSliderV2 = () => {
  const [active, setActive] = useState(0);
  const [sliderImages, setSliderImages] = useState([]);

  // useEffect(() => {
  //   if (active === 2) setActive(0);
  //   if (active === 68) setActive(0);
  // }, [active]);

  useEffect(() => {
    API.get("content/secondary_slide").then((resp) => {
      setSliderImages(resp.data.secondarySlides);
    });
  }, []);

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item, index) => {
  //     images[item.replace("./", "")] = r(item);
  //     return "";
  //   });
  //   return images;
  // }

  // const images = importAll(
  //   require.context("../../Assets/Images/Gallerie", false, /\.(png|jpe?g|svg)$/)
  // );

  // const imgKeys = Object.keys(images);
  // console.log(active);

  const transformStyle = {
    transform: `translateX(${-93 - active * 100}%)`,
  };

  return (
    <>
      {sliderImages.length !== 0 && (
        <div className="galleriesliderv2">
          <div
            className="angle_left"
            onClick={() => {
              if (active > -1) setActive((prev) => prev - 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} className="ar" />
          </div>
          <div
            className="angle_right"
            onClick={() => {
              if (active < sliderImages.length - 2)
                setActive((prev) => prev + 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} className="ar" />
          </div>
          <div className="imgs">
            {/* New idea */}
            {sliderImages.map((e, i) => {
              return (
                <div className="img" style={transformStyle}>
                  <img
                    src={
                      UPLOAD_URL[process.env.NODE_ENV] +
                      "others/" +
                      e.picture.filename
                    }
                    alt={`Gallerie ${i + 1}`}
                    key={i}
                  />
                </div>
              );
            })}
            {/* {imgKeys.map((e, i) => {
          return (
            <img
              src={images[e]}
              alt={`Gallerie ${i + 1}`}
              style={transformStyle}
              key={i}
            />
          );
        })}

        {imgKeys.map((e, i) => {
          return (
            <img
              src={images[e]}
              alt={`Gallerie ${i + 1}`}
              style={transformStyle}
              key={i}
            />
          );
        })}
        {imgKeys.map((e, i) => {
          return (
            <img
              src={images[e]}
              alt={`Gallerie ${i + 1}`}
              style={transformStyle}
              key={i}
            />
          );
        })} */}
            {/* old idea */}
            {/* {imgKeys.map((e, i) => {
          if (i === 0)
            return (
              <>
                <img
                  src={images[imgKeys[imgKeys.length - 1]]}
                  alt={`Gallerie ${i}`}
                  style={transformStyle}
                />
                <img
                  src={images[e]}
                  alt={`Gallerie ${i + 1}`}
                  style={transformStyle}
                />
              </>
            );
          if (i === imgKeys.length - 1)
            return (
              <>
                <img
                  src={images[e]}
                  alt={`Gallerie ${i}`}
                  style={transformStyle}
                />
                <img
                  src={images[imgKeys[0]]}
                  alt={`Gallerie ${i + 1}`}
                  style={transformStyle}
                />
              </>
            );
          return (
            <img
              src={images[e]}
              alt={`Gallerie ${i + 1}`}
              style={transformStyle}
            />
          );
        })}
        {imgKeys.map((e, i) => {
          if (i === 0)
            return (
              <>
                <img
                  src={images[imgKeys[imgKeys.length - 1]]}
                  alt={`Gallerie ${i}`}
                  style={transformStyle}
                />
                <img
                  src={images[e]}
                  alt={`Gallerie ${i + 1}`}
                  style={transformStyle}
                />
              </>
            );
          if (i === imgKeys.length - 1)
            return (
              <>
                <img
                  src={images[e]}
                  alt={`Gallerie ${i}`}
                  style={transformStyle}
                />
                <img
                  src={images[imgKeys[0]]}
                  alt={`Gallerie ${i + 1}`}
                  style={transformStyle}
                />
              </>
            );
          return (
            <img
              src={images[e]}
              alt={`Gallerie ${i + 1}`}
              style={transformStyle}
            />
          );
        })} */}
          </div>
          <h5> {(active % 12) + 2 + " OF " + sliderImages.length}</h5>
        </div>
      )}
    </>
  );
};

export default GallerieSliderV2;
