import { useState } from "react";
import "./select.css";
import { AnimatePresence, motion } from "framer-motion";
import { ListAnimation } from "src/Utils/animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useEffect } from "react";

const Select = ({ name, list, val, setVal, zIndex }) => {
  const ref = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const oc = (nb) => {
    setOpen((prev) => !prev);
    nb || nb === 0 ? setVal(nb) : <></>;
  };

  return (
    <div className="basic_select" ref={ref} style={{zIndex}}>
      {console.log(val)}
      <ul>
        <li
          onClick={() => {
            oc();
          }}
          className={open ? "li-open" : ""}
        >
          {" "}
          {val || val === 0 ? name + ` : ( ${val} )` : name}{" "}
          <span style={{ transform: open ? `rotate(-90deg)` : "" }}>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </li>
        <AnimatePresence>
          {list.map(
            (e, i) =>
              open && (
                <motion.li
                  key={i}
                  variants={ListAnimation}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className={open ? "li-open-s" : ""}
                  onClick={() => {
                    oc(e);
                  }}
                >
                  {e}
                </motion.li>
              )
          )}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default Select;
