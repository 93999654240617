import "./header.css";
import Logo from "src/Assets/Images/Logo.png";
import TripAdv from "src/Assets/Icons/tripadvisor_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

const Header = () => {
  // const [active, setActive] = useState(0);
  const [minActive, setMinActive] = useState(false);

	const navigate = useNavigate();

  return (
    <div className={!minActive ? "header" : "header active"}>
      <div className="header_logo">
        <img src={Logo} alt="Hotel Park Mall Logo" onClick={() => {
            navigate("/");
          }}/>
      </div>
      <div className="header_elements">
        <ul>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/"
              end
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Accueil</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/chambres"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Chambres</span>
            </NavLink>
          </li>
          {/* <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/conferences"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Conference</span>
            </NavLink>
          </li> */}
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Services</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/gallerie"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Gallerie</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Contact</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={minActive ? "social_media" : "social_media active"}>
        <a href="https://www.facebook.com/ParkMallHotelandCC">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/park_mall_hotel/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.tripadvisor.fr/Hotel_Review-g424904-d21321724-Reviews-Park_Mall_Hotel_Conference_Center-Setif_Setif_Province.html">
          <img src={TripAdv} alt="tripadvisor hotel park mall" />
        </a>
        <a href="https://www.google.com/maps/place/Park+Mall+Hotel+%26+Conference+Center/@36.1909922,5.4090147,17z/data=!3m1!4b1!4m8!3m7!1s0x12f3159953b40e55:0xaf505e345032e526!5m2!4m1!1i2!8m2!3d36.190879!4d5.4084284">
          <FontAwesomeIcon icon={faGoogle} />
        </a>
      </div>
      <div className="burgermenu" onClick={() => setMinActive((prev) => !prev)}>
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
};

export default Header;
