import "./room.css";
import { motion } from "framer-motion";
import { RoomAnimation } from "src/Utils/animation";

const Room = ({
  bol,
  id,
  img,
  type,
  price,
  delay,
  size,
  maxAdults,
  maxChildrens,
  nbLitsQueen,
  isConference,
}) => {
  return (
    <motion.div
      className="room"
      variants={RoomAnimation(delay)}
      initial="hidden"
      animate="visible"
      exit="exit"
      onClick={() => {
        window.location.href += id !== undefined ? "/" + id : "chambres";
      }}
    >
      <div className="img">
        <img src={img} alt="nop" />
      </div>
      <div className="description">
        <div className="price">
          From {price} {isConference ? "DA/Day" : "DA/Night"}
        </div>
        <h4>{type}</h4>
        <div className="icons">
          {[
            "stmicon-guest",
            "stmicon-nipple",
            "stmicon-bed2",
            "stmicon-ruler",
          ].map((e, i) => {
            return i === 2 ? (
              nbLitsQueen !== 0 && (
                <div className="icon" key={i}>
                  <i className={e} />
                  <span>{nbLitsQueen}</span>
                </div>
              )
            ) : i === 1 ? (
              maxChildrens !== 0 && (
                <div className="icon" key={i}>
                  <i className={e} />
                  <span>
                    {i === 0 ? maxAdults : i === 1 ? maxChildrens : size}
                  </span>
                </div>
              )
            ) : (
              <div className="icon" key={i}>
                <i className={e} />
                <span>{i === 0 ? maxAdults : size}</span>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default Room;
