const RoomsData = {
  standard: [
    {
      img: "standard_double.jpg",
      type: "Standard Double",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 1 },
        { icon: "stmicon-bed2", nb: "2 Queens" },
        { icon: "stmicon-ruler", nb: 30 },
      ],
    },
    {
      img: "standard_single.jpg",
      type: "Standard Single",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 1 },
        { icon: "stmicon-ruler", nb: 28 },
      ],
    },
  ],
  deluxe: [
    {
      img: "delux_double.jpg",
      type: "Delux Double",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 2 },
        { icon: "stmicon-bed2", nb: "2 Queens" },
        { icon: "stmicon-ruler", nb: 38 },
      ],
    },
    {
      img: "delux_single.jpg",
      type: "Delux Single",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 1 },
        { icon: "stmicon-ruler", nb: 34 },
      ],
    },
  ],
  business: [],
  suite: [
    {
      img: "suite_senior.jpg",
      type: "Suite Senior",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 1 },
        { icon: "stmicon-ruler", nb: 78 },
      ],
    },
    {
      img: "suite_junior.jpg",
      type: "Suite Junior",
      price: "Check it out",
      icons: [
        { icon: "stmicon-guest", nb: 2 },
        { icon: "stmicon-nipple", nb: 1 },
        { icon: "stmicon-ruler", nb: 45 },
      ],
    },
  ],
};

export default RoomsData;
