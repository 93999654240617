const servData = {
  img1: {
    title: "CHAMBRES & SUITES",
    description:
      "Détendez-vous dans l’une de nos 191 chambres équipées d’une grande télé de 42 po avec une tasse de café ou de thé et passez une bonne nuit sur notre Park Mall Hotel Confort lit.",
  },
  img2: {
    title: "RESTAURATION GASTRONOMIQUE",
    description:
      "Vos petits dejeuners, dejeuners et diners vous seront servis en buffet variés au restaurant Zitoune. Et profitez d’une vue imprenable sur toute la ville de Sétif en savourant nos delicieux repas au restaurant italien Brezza du 17éme étage.",
  },
  img3: {
    title: "SALLES DE CONFERENCES",
    description:
      "Pour une réunion, un mariage ou tout autre événement spécial, nous disposons de 1225m² d’espace de réunion et de banquet pouvant accueillir jusqu’à 800 participants.",
  },
  img4: {
    title: "LES EXTRAS",
    description:
      "Pendant votre séjour, bénéficiez d’un accés a la salle de gym équipée, ainsi qu’à la piscine durant la saison estivale avec une formidable vue sur le Park d’attraction.",
  },
};

export default servData;
