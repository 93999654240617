const WhyUsData = [
  { icon: "stmicon-hotel-internet", title: "INTERNET HAUT DEBIT" },
  { icon: "stmicon-hotel-drinks", title: "BOISSONS ORIGINALES ET SANS ALCOOL" },
  { icon: "stmicon-hotel-concierge", title: "ROOM SERVICE" },
  { icon: "stmicon-hotel-pool", title: "PISCINE" },
  { icon: "stmicon-hotel-fitness", title: "SALLE DE SPORT" },
  { icon: "stmicon-hotel-laundry", title: "CONCIERGERIE" },
  { icon: "stmicon-hotel-restaurant", title: "RESTAURANT GASTRONOMIQUE" },
  { icon: "stmicon-hotel_parking-1", title: "PARKING GRATUIT" },
];

export default WhyUsData;