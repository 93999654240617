import { useEffect, useState } from "react";
import { Header, Footer, Loader } from "src/Components";

import API from "src/API";

import { useParams, useNavigate } from "react-router-dom";

import Cib from "src/Assets/Images/cib.png";

import Satim from "src/Assets/Images/satim.png";

// const ErrorModal = ({ setErrorActive }) => {
//   return (
//     <div className="errormodal">
//       <div className="wrapperblur">
//         <div className="content">
//           <h3>
//             Something went wrong, Check your inputs & make sure you check the
//             accept conditions box?.
//           </h3>
//           <div className="buttons">
//             <div
//               className="fullbutton cancel"
//               onClick={() => {
//                 setErrorActive(false);
//               }}
//             >
//               Close
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const PyamentRedirect = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [arrival, setArrival] = useState("");
  const [requests, setRequests] = useState("");
  const [checkIn, setCheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(new Date());
  const [nbAdult, setNbAdult] = useState(0);
  const [nbChildren, setNbChildren] = useState(0);
  const [room, setRoom] = useState("");
  const [link, setLink] = useState("");
  //   const [loading, setLoading] = useState(false);
  //   const [online, setOnline] = useState(true);
  //   const [notOnlinePossible, setNotOnlinePossible] = useState(false);
  //   const [success, setSuccess] = useState(false);

  //   const [errP, setErrP] = useState();
  //   const [errNf, setErrNf] = useState();
  //   const [errEm, setErrEm] = useState();
  //   const [errPh, setErrPh] = useState();

  //   const [captcha, setCaptcha] = useState(false);
  const [conditions, setConditions] = useState(false);
  const [loadin, setLoading] = useState(true);

  const [price, setPrice] = useState("Price");

  const navigate = useNavigate();

  //   const [errorActive, setErrorActive] = useState(false);
  const { orederId } = useParams();

  useEffect(() => {
    API?.get(`reservations/order/${orederId}`)
      ?.then((resp) => {
        let reservation = resp?.data?.reservation;

        setFname(reservation?.user[0]?.firstname);
        setLname(reservation?.user[0]?.lastname);
        setEmail(reservation?.user[0]?.email);
        setPhone(reservation?.user[0]?.phone);
        setArrival(reservation?.arrival_time);
        setRequests(reservation?.more);
        setNbAdult(reservation?.nb_adults);
        setNbChildren(reservation?.nb_children);
        setCheckIn(new Date(reservation?.checkin_date));
        setCheckOut(new Date(reservation?.checkout_date));
        setRoom(reservation?.room?.description);
        setPrice(reservation?.price);
        setLink(reservation?.payment?.form_url);
        setLoading(false);
      })
      .catch(() => {
        navigate("/404");
      });
  }, []);

  return (
    <>
      {loadin ? (
        <div className="full_loading">
          <Loader />
        </div>
      ) : (
        <div className="paymentform">
          <Header />
          <div className="container">
            {/* {errorActive && <ErrorModal setErrorActive={setErrorActive} />} */}
            <div className="left">
              <h3>Réservation Directe</h3>
              <div>
                <i className="stmicon-phone" /> <span>036 81 41 41(42)</span>
              </div>
              <div>
                <i className="stmicon-email" />{" "}
                <span>reservation@parkmallhotel.com</span>
              </div>
            </div>
            <div className="right">
              <form>
                <div className="input">
                  <span>Prénom</span>
                  <input placeholder="Prénom" value={fname} readOnly disabled />
                </div>
                <div className="input">
                  <span>Nom de famille </span>
                  <input
                    placeholder="Nom de famille "
                    value={lname}
                    readOnly
                    disabled
                  />
                </div>
                <div className="input">
                  <span>Adresse e-mail</span>
                  <input
                    placeholder="Adresse e-mail"
                    value={email}
                    readOnly
                    disabled
                  />
                </div>
                <div className="input">
                  <span>Téléphone </span>
                  <input
                    placeholder="Téléphone"
                    value={phone}
                    readOnly
                    disabled
                  />
                </div>
                <div className="input">
                  <span>Time of arrival</span>
                  <input placeholder="14" value={arrival} readOnly disabled />
                  <span className="desc">
                    L’heure correspond au fuseau horaire Sétif
                  </span>
                </div>
                <div className="input full">
                  <span>Demandes spéciales</span>
                  <textarea rows={4} value={requests} readOnly disabled />
                  <span className="desc">
                    Special requests cannot be guaranteed but we will do our
                    best to meet your needs?.
                  </span>
                </div>
              </form>
              <div className="bookingdetails">
                <h3>Booking details</h3>
                <div className="detail">
                  <span>Check-in</span>
                  <span>
                    {checkIn.toISOString()?.slice(0, 10)} , 15:00
                  </span>
                </div>
                <div className="detail">
                  <span>Check-out</span>
                  <span>
                    {checkOut.toISOString()?.slice(0, 10)} , 12:00
                  </span>
                </div>
                {/* <div className="detail">
              <span>Pets</span>
              <span>Les animaux ne sont pas acceptés?.</span>
            </div> */}
              </div>
              <div className="yourreservation">
                <h3>Booking details</h3>
                <div className="head">
                  <span className="rm">Type de Chambre</span>
                  <span className="nbadult">Nombre d'Adultes</span>
                  <span className="nbchildren">Nombre d'Enfants</span>
                  <span className="nb nights">Nombre de nuits</span>
                </div>
                <div className="element">
                  <span className="rm">{room}</span>
                  <span className="nbadult">{nbAdult}</span>
                  <span className="nbchildren">{nbChildren}</span>
                  <span className="nb nights">
                    {Math.round((checkOut - checkIn) / (24 * 60 * 60 * 1000))}
                  </span>
                </div>
                <div className="total">
                  <span>Total:</span>
                  <span>{price}</span>
                </div>
              </div>
              <h2>la Methode de paiment</h2>
              <div className="paymentmethod">
                <span className={"active"}>
                  Payement Online en utilisant une carte CIB
                  <img src={Satim} alt="satim e-payment" />
                </span>
              </div>
              {/* <div className="paymentmethod">
              <ReCAPTCHA
                sitekey="6LeXb-giAAAAAG71wisSuLOKb5Mr9C843zrX3nXu"
                onChange={(val) => {
                  setCaptcha(true);
                }}
              />
            </div> */}
              <p>
                Your personal data will be used to support your experience
                throughout this website, to process your reservations
              </p>
              <p>
                Accepter{" "}
                <a href="/policy" target="_blank">
                  {" "}
                  les condition d'utilisation{" "}
                </a>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setConditions((prev) => !prev);
                  }}
                />
              </p>
              <button
                disabled={!conditions}
                onClick={() => {
                  if (link) {
                    window.location = link;
                  }
                }}
              >
                <img src={Cib} alt="Payment CIB/Edahabia" />
                Proceed To Payment
              </button>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default PyamentRedirect;
