const services3Data = [
  { icon: "stmicon-car", title: "Parking Gratuit" },
  { icon: "stmicon-smoke_free", title: "Non Fumeur" },
  { icon: "stmicon-laundry-service", title: "Service de Buanderie" },
  { icon: "stmicon-safe_secure", title: "Coffre fort" },
  { icon: "stmicon-wifi-1", title: "Wifi Gratuit" },
  { icon: "stmicon-television", title: "Télévision" },
];

export default services3Data;
