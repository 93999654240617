import { useEffect, useState } from "react";
import "./gellerieslider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { GallerieAnimation } from "src/Utils/animation";
import { Loader } from "src/Components";
import { UPLOAD_URL } from "src/constants";

//Because i was very tired today this wasn't a good code ,can be improved using variables later or refactor the old code and think of another way to make the animation

const GallerieSlider = ({ imagesSlider }) => {
  const [active, setActive] = useState(0);
  const [imagesRoom, setImagesRoom] = useState(JSON.parse(imagesSlider));

  //to refactor the old code make this function return an array with the three active slides
  //   const displayRules = (i, l) => {
  //     let a = [...Array(l).keys()].map((i) => false);
  //     if (i >= l) {
  //       a[l - 1] = true;
  //       a[l] = true;
  //       a[0] = true;
  //     }
  //     if (i === 0) {
  //       a[l] = true;
  //       a[i] = true;
  //       a[i + 1] = true;
  //     } else {
  //       a[i - 1] = true;
  //       a[i] = true;
  //       a[i + 1] = true;
  //     }
  //     return a;
  //   };

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item, index) => {
  //     images[item.replace("./", "")] = r(item);
  //     return "";
  //   });
  //   return images;
  // }

  // const images = importAll(
  //   require.context(
  //     "../../../Assets/Images/Gallerie",
  //     false,
  //     /\.(png|jpe?g|svg)$/
  //   )
  // );

  // const imgKeys = Object.keys(images);

  return (
    <div className="gallerieslider">
      {imagesRoom.length !== 0 ? (
        <>
          <div className="active_img">
            <div
              className="angle_left"
              onClick={() => {
                if (active === 0) setActive(imagesRoom.length - 1);
                else setActive((prev) => prev - 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} className="ar" />
            </div>
            <div
              className="angle_right"
              onClick={() => {
                if (active === imagesRoom.length - 1) setActive(0);
                else setActive((prev) => prev + 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleRight} className="ar" />
            </div>
            {imagesRoom.map((e, i) => {
              return (
                <AnimatePresence key={i}>
                  {active === i && (
                    <div className="img">
                      <motion.img
                        variants={GallerieAnimation}
                        initial="hidden"
                        animate="visible"
                        src={UPLOAD_URL[process.env.NODE_ENV] +'rooms/'+ e}
                        alt="Gallerie"
                      />
                    </div>
                  )}
                </AnimatePresence>
              );
            })}
          </div>
          {/* <div className="imgs">
        {active === 0 ? (
          <>
            {displayRules(active, imgKeys.length - 1)[imgKeys.length - 1] && (
              <div className="slide">
                <img
                  src={images[imgKeys[imgKeys.length - 1]]}
                  alt={`Gallerie`}
                />
              </div>
            )}
            {displayRules(active, imgKeys.length - 1)[0] && (
              <div className="slide">
                <div
                  className="angle_left"
                  onClick={() =>
                    setActive((prev) =>
                      prev === 0 ? imgKeys.length - 1 : prev - 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="ar" />
                </div>
                <div
                  className="angle_right"
                  onClick={() =>
                    setActive((prev) =>
                      prev === imgKeys.length - 1 ? 0 : prev + 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faAngleRight} className="ar" />
                </div>
                <img src={images[imgKeys[0]]} alt="gallerie" />
              </div>
            )}
            {displayRules(active, imgKeys.length - 1)[1] && (
              <div className="slide">
                <img src={images[imgKeys[1]]} alt={`Gallerie`} />
              </div>
            )}
          </>
        ) : active === imgKeys.length - 1 ? (
          <>
            {displayRules(active, imgKeys.length - 1)[imgKeys.length - 2] && (
              <div className="slide">
                <img
                  src={images[imgKeys[imgKeys.length - 2]]}
                  alt={`Gallerie`}
                />
              </div>
            )}
            {displayRules(active, imgKeys.length - 1)[imgKeys.length - 1] && (
              <div className="slide">
                <div
                  className="angle_left"
                  onClick={() =>
                    setActive((prev) =>
                      prev === 0 ? imgKeys.length - 1 : prev - 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="ar" />
                </div>
                <div
                  className="angle_right"
                  onClick={() =>
                    setActive((prev) =>
                      prev === imgKeys.length - 1 ? 0 : prev + 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faAngleRight} className="ar" />
                </div>
                <img src={images[imgKeys[imgKeys.length - 1]]} alt="Galleri" />
              </div>
            )}
            {displayRules(active, imgKeys.length - 1)[0] && (
              <div className="slide">
                <img src={images[imgKeys[0]]} alt={`Gallerie`} />
              </div>
            )}
          </>
        ) : (
          imgKeys.map((e, i) => {
            if (i === active)
              return (
                displayRules(active, imgKeys.length - 1)[i] && (
                  <div className="slide">
                    <div
                      className="angle_left"
                      onClick={() =>
                        setActive((prev) =>
                          prev === 0 ? imgKeys.length - 1 : prev - 1
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="ar" />
                    </div>
                    <div
                      className="angle_right"
                      onClick={() =>
                        setActive((prev) =>
                          prev === imgKeys.length - 1 ? 0 : prev + 1
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} className="ar" />
                    </div>
                    <img src={images[e]} alt="Gallerie" />
                  </div>
                )
              );
            return (
              displayRules(active, imgKeys.length - 1)[i] && (
                <div className="slide">
                  <img src={images[e]} alt={`Gallerie ${i}`} key={i} />
                </div>
              )
            );
          })
        )}
        <img
          src={images[imgKeys[displayRules(active, imgKeys.length - 1)[0]]]}
          alt="gallerie image"
        />
        <div className="slide">
          <div
            className="angle_left"
            onClick={() =>
              setActive((prev) => (prev === 0 ? imgKeys.length - 1 : prev - 1))
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} className="ar" />
          </div>
          <div
            className="angle_right"
            onClick={() =>
              setActive((prev) => (prev == imgKeys.length - 1 ? 0 : prev + 1))
            }
          >
            <FontAwesomeIcon icon={faAngleRight} className="ar" />
          </div>
          <img
            src={images[imgKeys[displayRules(active, imgKeys.length - 1)[1]]]}
            alt="galeria image"
          />
        </div>
        <img
          src={images[imgKeys[displayRules(active, imgKeys.length - 1)[2]]]}
          alt="galeria image"
        />
      </div> */}
          <h5>{active + 1 + " OF " + imagesRoom.length}</h5>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GallerieSlider;
