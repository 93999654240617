import './item.css';

const Item = ({cName, title, time, color, size}) => {
    return <div className='item'>
        <i className={cName} style={{color: color, fontSize: size}}/>
        <div className="desc">
            <h5>{title}</h5>
            <span>{time}</span>
        </div>
    </div>
}

export default Item;