import "./footer.css";
import TripAdv from "src/Assets/Icons/tripadvisor_black.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer">
      <h6> Copyright © 2022 Park Mall Hotel & Conference Center Sétif </h6>
      <div className="social_media">
        <a href="https://www.facebook.com/ParkMallHotelandCC">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/park_mall_hotel/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.tripadvisor.fr/Hotel_Review-g424904-d21321724-Reviews-Park_Mall_Hotel_Conference_Center-Setif_Setif_Province.html">
          <img src={TripAdv} alt="tripadvisor hotel park mall" />
        </a>
        <a href="https://www.google.com/maps/place/Park+Mall+Hotel+%26+Conference+Center/@36.1909922,5.4090147,17z/data=!3m1!4b1!4m8!3m7!1s0x12f3159953b40e55:0xaf505e345032e526!5m2!4m1!1i2!8m2!3d36.190879!4d5.4084284">
          <FontAwesomeIcon icon={faGoogle} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
