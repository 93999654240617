const services2Data = [
  {
    img: "img1.jpg",
    icon: "stmicon-bell",
    title: "Room Service",
    description:
      "Notre service de chambre est disponible 24h/24h tous les jours",
  },
  {
    img: "img2.jpg",
    icon: "stmicon-restaurant_menu",
    title: "Restos & Lounge",
    description:
      "Le restaurant italien Brezza au 17éme étage & le restaurant buffet Zitoune au 3éme étage",
  },
  {
    img: "img3.jpg",
    icon: "stmicon-hotel-meetings",
    title: "Events & Meetings",
    description:
      "Pour vos réunions, nous disposons de 8 salles de conférences et de 1225m² d’espace banquet",
  },
  {
    img: "img4.jpg",
    icon: "stmicon-hotel-fitness",
    title: "Piscine & Gym",
    description:
      "En reservant chez nous, vous aurez un accés gratuit a la piscine et a la salle de gym",
  },
];

export default services2Data;
