import './policy.css';

const Policy = () => {
  return (
    <div className="policy">
      <h2>ARTICLE 1 – Champ d’application</h2>
      Les présentes Conditions Générales de Vente s’appliquent, sans restriction
      ni réserve à tout achat des services de réservation de chambres d’hôtel et
      de prestations annexes (‘les Services‘) proposés par l’Hôtel aux
      consommateurs et clients non professionnels sur le site Internet de
      l’Hôtel.
      <br></br>Les caractéristiques principales des Services, sont présentées
      sur le site internet de l’Hôtel.
      <br></br>Le Client est tenu d’en prendre connaissance avant toute
      réservation. Le choix et l’achat d’un Service est de la seule
      responsabilité du Client.
      <br></br>Le Client déclare :<br></br>● Avoir la pleine capacité juridique
      de s’engager au titre des présentes Conditions Générales de Ventes.
      <br></br>● Effectuer la réservation de chambres d’hôtel et de prestations
      annexes pour ses besoins personnels.
      <br></br>● Etre en mesure de sauvegarder et imprimer les présentes
      Conditions Générales de ventes. Les coordonnées de l’Hôtel sont les
      suivantes : ……………………
      <br></br>Ces conditions s’appliquent à l’exclusion de toutes autres
      conditions, et notamment celles applicables pour d’autres circuits de
      commercialisation des Services.
      <br></br>Il est porté à la connaissance du Client que l’Hôtel conclut des
      accords de partenariat avec des prestataires de voyage tiers afin de lui
      permettre, en utilisant les services proposés par ces partenaires sur leur
      site internet, de rechercher, sélectionner et réserver des chambres dans
      l’Hôtel. Toute réservation de chambres d’hôtel réalisée dans ces
      conditions implique la consultation et l’acceptation complète et sans
      réserve par le Client des conditions particulières du prestataire, des
      conditions de vente du tarif réservé et des présentes conditions générales
      de vente. Le Client déclare avoir obtenu de l’Hôtel toutes les
      informations nécessaires et disponibles sur le site Internet.
      <br></br>Les présentes Conditions Générales de Vente sont accessibles à
      tout moment sur le site Internet de l’Hôtel et prévaudront, le cas
      échéant, sur toute autre version ou tout autre document contradictoire.
      <br></br>Sauf preuve contraire, les données enregistrées dans le système
      informatique du prestataire constituent la preuve de l’ensemble des
      transactions conclues avec le Client. Ainsi, la saisie des informations
      bancaires, l’acceptation des Conditions Générales de Vente, des Conditions
      de Vente du tarif ou de la Demande de réservation, à entre l’Hôtel et le
      Client la même valeur qu’une signature manuscrite sur support papier. Les
      Registres informatisés conservés dans les systèmes informatiques de
      l’Hôtel seront conservés dans des conditions raisonnables de sécurité et
      considérés comme des preuves de communication, commande et paiement
      intervenues entre l’Hôtel et le Client.
      <br></br>L’Hôtel assure la conservation de l’écrit constatant la
      conclusion du contrat sous format électronique ou papier pendant une durée
      maximale de 5 ans.
      <br></br>Les présentes Conditions Générales de Vente comprennent également
      la Charte sur les Données Personnelles.
      <br></br>Le Client déclare avoir pris connaissance des présentes
      Conditions Générales de Ventes (y compris la Charte sur les Données
      Personnelles) et les avoir acceptées en cochant la case prévue à cet effet
      avant la mise en œuvre de la procédure de réservation en ligne ainsi que
      des Conditions Générales d’Utilisation du site internet de l’Hôtel.
      <br></br>La validation de la réservation de Services par le Client vaut
      acceptation sans restriction ni réserve des présentes Conditions Générales
      de Vente (y compris la Charte sur les Données Personnelles).
      <br></br>Le Client reconnaît avoir la capacité requise pour contracter et
      acquérir les Services proposés sur le site internet de l’Hôtel.
      <br></br>Ces Conditions Générales de Vente pouvant faire l’objet de
      modifications ultérieures, la version applicable à l’achat du Client est
      celle en vigueur sur le site internet à la date de la réservation.
      <br></br>Les présentes Conditions Générales de Vente sont applicables
      pendant la durée de mise en ligne des services proposées par l’Hôtel sur
      le Site internet de l’Hôtel. L’Hôtel se réserve le droit de fermer
      temporairement ou définitivement l’accès de son Site internet.
      <h2>ARTICLE 2 – Réservations</h2>
      Le Client sélectionne sur le site Internet les services qu’il désire
      réserver, selon les modalités suivantes :<br></br>1. Sélection du type de
      chambre et du tarif
      <br></br>2. Sélection des prestations annexes le cas échéant
      <br></br>3. Vérification et validation du détail de la réservation, du
      montant total de la réservation, des conditions du tarif
      <br></br>4. Indication de ses coordonnées
      <br></br>5. Saisie des coordonnées de sa carte bancaire en cas de garantie
      ou de prépaiement
      <br></br>6. Consultation et acceptation des conditions générales de vente
      et des conditions du tarif sélectionné préalablement à la validation de sa
      réservation
      <br></br>7. Validation de sa réservation
      <br></br>Le Client reconnait avoir pris connaissance de la nature, la
      destination et les modalités de réservation des Services proposées par
      l’Hôtel et avoir sollicité et obtenu les informations nécessaires pour
      effectuer sa réservation en toute connaissance de cause. Il est seul
      responsable de son choix de services et de leur adéquation à ses besoins,
      de telle sorte que la responsabilité de l’Hôtel ne peut être recherchée à
      cet égard.
      <br></br>Le Client s’engage à compléter les informations demandées sur la
      demande de réservation et atteste la véracité et l’exactitude des
      informations transmises.
      <br></br>Les informations contractuelles sont présentées en langue
      française et font l’objet d’une confirmation au plus tard au moment de la
      validation de la réservation par le Client.
      <br></br>Pour les réservations passées exclusivement sur internet,
      l’enregistrement d’une réservation sur le site du Prestataire est réalisé
      lorsque le Client accepte les présentes Conditions Générales de Vente en
      cochant la case prévue à cet effet et valide sa réservation. Le Client a
      la possibilité de vérifier le détail de sa réservation, son prix total et
      de corriger d’éventuelles erreurs avant de confirmer son acceptation.
      Cette validation implique l’acceptation de l’intégralité des présentes
      Conditions Générales de Vente et constituent une preuve du contrat de
      vente.
      <br></br>Il appartient donc au Client de vérifier l’exactitude de la
      réservation et de signaler immédiatement toute erreur.
      <br></br>La vente de Services ne sera considérée comme définitive qu’après
      l’envoi au Client de la confirmation de l’acceptation de la réservation
      par le Prestataire, par courrier électronique et après encaissement par
      celui-ci de l’intégralité du prix.
      <br></br>Toute réservation passée sur le site internet de l’Hôtel
      constitue la formation d’un contrat conclu à distance entre le Client et
      le Prestataire.
      <br></br>L’Hôtel se réserve le droit d’annuler ou de refuser toute
      réservation d’un Client avec lequel il existerait un litige relatif au
      paiement d’une réservation antérieure.
      <br></br>Chaque réservation est nominative et ne peut en aucun cas être
      cédée à un tiers.
      <br></br>Annulation d’une réservation au tarif Flexible :<br></br>En cas
      d’annulation de la réservation au tarif Flexible par le Client après son
      acceptation par l’Hôtel moins de 24 heures avant la date prévue de séjour,
      pour quelque raison que ce soit, une somme correspondant au montant total
      de la 1ère nuitée sera de plein droit acquise au Prestataire et facturée
      au Client, à titre de dommages et intérêts, en réparation du préjudice
      subi.
      <br></br>Annulation / Modification d’une réservation au tarif Non
      Annulable Non Remboursable :<br></br>En cas d’annulation ou modification
      de la réservation au tarif Non annulable non remboursable par le Client,
      pour quelque raison que ce soit, une somme correspondant à 100% du montant
      total prépayé sera de plein droit acquise au Prestataire et facturée au
      Client. Il en sera de même en cas de non présentation du Client à la date
      prévue de son arrivée.
      <br></br>• Pour toute demande de chambre (check in) avant 06h, Une nuitée
      sera facturée.
      <br></br>• Pour toute demande de chambre (check in) entre 06h et 08h, un
      montant de 6000DZD vous sera facturé.
      <br></br>• Pour toute demande de chambre (check in) entre 08h et 14h, un
      montant de 4000DZD vous sera facturé.
      <br></br>• Départ Anticipé : en cas de départ anticipé non signalé avant
      les 24h une nuitée sera facturée.
      <br></br>• Départ tardif : Toute demande de départ tardif devra être
      adresser à la réception un forfait de 1000 DZD par heure sera proposé. A
      partir de 18h une nuitée sera facturée.
      <br></br>• Le paiement du séjour ainsi qu’une caution de 5000 DZD par
      personne et par nuit pourra être demandé au moment du check in couvrant
      les extras.
      <br></br>• Une pièce d’identité en cours de validité avec photo est
      requise au moment de l’enregistrement.
      <br></br>• Le livret de famille est exigé pour tout couple de nationalité
      Algérienne souhaitant partager une chambre.
      <br></br>• L'hôtel ne propose pas et n'accepte pas les boissons
      alcoolisées et la « Chicha ».
      <br></br>• L’hôtel ne dispose pas de chambres fumeurs, des terrasses
      extérieurs dans l’hôtel sont réservées pour cela.
      <br></br>• Une amande de 25000 DA sera facturée dans le cas où la
      condition précédente ne sera pas respectée.
      <br></br>• L’hôtel n’accepte pas l’introduction de nourriture provenant de
      l’extérieur.
      <br></br>• L’hôtel n’accepte pas l’introduction d’animaux domestiques ou
      autres dans les chambres ou espaces publiques.
      <br></br>• Pour toute détérioration des biens de l’hôtel dans les chambres
      ou espaces publiques le montant de l’objet en question devra être
      remboursé.
      <br></br>• L’hôtel se réserve le droit de refuser des clients ne
      respectant pas les conditions de séjours citées.
      <br></br>• L’hôtel n’accepte pas les chèques personnels. Les modes de
      paiements autorisés sont : Espèces, chèques bancaires certifiés et cartes
      de crédit (CIB, Visa, Master Card).
      <br></br>• L’hôtel se réserve le droit de résilier le contrat
      d’hébergement dans les cas suivants :<br></br> * Comportement indécent du
      client
      <br></br> * Maladie contagieuse du client, légalement constatée
      <br></br>* Introduction par le client à l’insu de l’hôtelier de tout
      produit toxique ou dangereux, armes et matériels interdits.
      <br></br>* Lorsque le client refuse de se soumettre aux mesures de control
      de sécurité.
      <h2>ARTICLE 3 – TARIFS</h2>
      Les Services proposés par le Prestataire sont fournis aux tarifs en
      vigueur sur le site internet de l’Hôtel lors de l’enregistrement de la
      réservation par le Prestataire. Les prix sont exprimés en Dinars et TTC.
      <br></br>Les tarifs tiennent compte d’éventuelles réductions qui seraient
      consenties par le Prestataire dans les conditions précisées sur le site
      internet de l’Hôtel.
      <br></br>Ces tarifs sont fermes et non révisables pendant leur période de
      validité, telle qu’indiqué sur le site internet de l’Hôtel, le Prestataire
      se réservant le droit, hors cette période de validité, de modifier les
      prix à tout moment.
      <br></br>Les tarifs sont indiqués avant et lors de la réservation faite
      par le Client. Ils s’entendent par chambre pour le nombre de personnes et
      la date sélectionnée.
      <br></br>Les tarifs sont confirmés au Client en montant TTC dans la devise
      commerciale de l’Hôtel. Ils tiennent compte de la TVA au taux applicable
      au jour de la réservation et de la Taxe de séjour ; tout changement du
      taux applicable à la TVA sera automatiquement répercuté sur les tarifs
      indiqués à la date de facturation. Il sera de même de toute modification
      ou instauration de nouvelles taxes légales ou réglementaires imposées par
      les Autorités compétentes.
      <br></br>Une facture est établie par le Prestataire et remise au Client
      lors de la fourniture des Services réservés.
      <h2>ARTICLE 4 – MODALITES de paiement</h2>
      En cas de paiement au comptant au jour de la réservation
      <br></br>Le prix est payable comptant, en totalité au jour de la
      confirmation de la réservation par le Client, selon les modalités
      précisées à l’article «Réservations» ci-dessus, par voie de paiement
      sécurisé : – par cartes bancaires : CIB et EL DHAHABIA
      <br></br>Lors de la réservation, le Client communique ses coordonnées
      bancaires en précisant le nom de la carte bancaire, le numéro de la carte
      bancaire, la date de validité (la carte bancaire doit être valable jusqu’à
      la date de fin du séjour) et le cryptogramme.
      <br></br>Les données de paiement sont échangées en mode crypté grâce au
      protocole SSL.
      <br></br>Le Client se présentera à l’Hôtel avec la carte bancaire lui
      ayant permis de réaliser le paiement de la réservation. Il pourra lui être
      demandé de présenter une pièce d’identité dans le cadre des procédures de
      prévention contre la fraude aux cartes bancaires.
      <br></br>Le Prestataire ne sera pas tenu de procéder à la fourniture des
      Services commandés par le Client si le prix ne lui a pas été préalablement
      réglé en totalité dans les conditions et ci-dessus indiquées.
      <br></br>Les paiements effectués par le Client ne seront considérés comme
      définitifs qu’après encaissement effectif des sommes dues par le
      Prestataire.
      <br></br>En cas de paiement au comptant à la fourniture des services
      (Tarif Flexible) :<br></br>Le prix est payable comptant, en totalité au
      jour de la fourniture des Services réservés dans les conditions définies à
      l’article «Fourniture des Services» ci-après et comme indiqué sur la
      facture remise au Client, par voie de paiement sécurisé : – par cartes
      bancaires : CIB et EL DHAHABIA
      <br></br>Les données de paiement sont échangées en mode crypté grâce au
      protocole SSL.
      <br></br>L’Hôtel demandera au Client, lors de son arrivée, de régler un
      dépôt de garantie ou d’autoriser le débit de sa carte bancaire, en vue de
      garantir le paiement des sommes correspondant aux prestations consommées
      sur place.
      <br></br>Le Client communiquera alors ses coordonnées bancaires en
      précisant le nom de la carte bancaire, le numéro de la carte bancaire, la
      date de validité (la carte bancaire doit être valable jusqu’à la date de
      fin du séjour) et le cryptogramme.
      <br></br>Il pourra être demandé au Client de présenter une pièce
      d’identité dans le cadre des procédures de prévention contre la fraude aux
      cartes bancaires.
      <br></br>Le Prestataire ne sera pas tenu de procéder à la fourniture des
      Services commandés par le Client si le prix ne lui a pas été préalablement
      réglé en totalité dans les conditions et ci-dessus indiquées.
      <br></br>Les paiements effectués par le Client ne seront considérés comme
      définitifs qu’après encaissement effectif des sommes dues par le
      Prestataire.
      <h2>ARTICLE 5 – Fourniture des Prestations</h2>
      Les Services réservés par le Client, qui comprennent les prestations de
      réservation de chambres d’hôtel et de prestations annexes seront fournis
      selon les modalités suivantes, dans les conditions prévues aux présentes
      Conditions Générales de Vente complétées par les Conditions de vente du
      Tarif dont le Client a pris connaissance et qu’il a acceptées lors de sa
      réservation sur le site Internet de l’Hôtel.
      <br></br>A son arrivée, il sera demandé au Client de présenter sa pièce
      d’identité afin de s’assurer de son obligation de compléter une Fiche de
      Police.
      <br></br>Les animaux ne sont pas admis dans l’hôtel.
      <br></br>Les effets personnels du Client laissés dans la chambre de
      l’Hôtel, notamment à l’extérieur du coffre-fort ou dans les espaces
      publics de l’Hôtel relèvent de son entière responsabilité. L’Hôtel ne
      saurait être tenu responsable de la perte, du vol, des détériorations ou
      des dommages causés auxdits effets.
      <br></br>Le client accepte et s’engage à utiliser la chambre en bon père
      de famille. Aussi tout comportement contraire aux bonnes mœurs et à
      l’ordre public amènera l’Hôtel à demander au Client de quitter
      l’établissement sans aucune indemnité et ou sans aucun remboursement si un
      règlement a déjà été effectué. Dans le cas où aucun règlement n’a encore
      été effectué, le client devra s’acquitter du prix des nuitées consommées
      avant de quitter l’établissement.
      <br></br>Le Client sera tenu responsable de l’intégralité des dommages
      directs et/ou indirects, consécutifs, dont il est l’auteur, constatés dans
      la chambre réservée ou qu’il pourrait causer au sein de l’Hôtel. En
      conséquence, il s’engage à indemniser l’Hôtel à hauteur du montant desdits
      dommages, sans préjudice des dommages et intérêts qui pourraient être dus,
      frais de procédure et d’avocats engagés par l’Hôtel.
      <br></br>Sauf disposition expresse contraire, la Chambre sera mise à la
      disposition du Client le jour de son arrivée à 15 heures et le Client
      quittera la chambre le jour de son départ à 12 heures. A défaut, une
      nuitée supplémentaire sera facturée au Client. Le Client devra vérifier sa
      date de départ. En cas de départ anticipé, des frais équivalents à une
      nuitée seront imputés, sauf si le Client en a avisé l’Hôtel au moins 24
      heures avant son départ.
      <br></br>Le Prestataire s’engage à faire ses meilleurs efforts pour
      fournir les Services réservés par le Client, dans le cadre d’une
      obligation de moyen.
      <br></br>Le Client disposera d’un délai de … jours à compter de sa date de
      départ de l’Hôtel pour émettre, par écrit, des réserves ou réclamations
      concernant la fourniture des Services, avec tous les justificatifs y
      afférents, auprès de l’Hôtel.
      <br></br>Aucune réclamation ne pourra être valablement acceptée en cas de
      non-respect de ces formalités et délais par le Client.
      <br></br>A défaut de réserves ou réclamations expressément émises dans ce
      délai par le Client lors de la réception des Services, ceux-ci seront
      réputés conformes à la réservation, en quantité et qualité. En cas de
      délogement :<br></br>En cas d’évènement exceptionnel, cas de force majeure
      ou d’impossibilité de mettre la chambre réservée à disposition du Client,
      l’Hôtel se réserve la possibilité de faire héberger totalement ou
      partiellement le Client dans un hôtel de catégorie équivalente, pour des
      prestations de même nature et sous réserve de l’accord préalable du
      Client.
      <h2>ARTICLE 6 – Responsabilité du Prestataire – Garantie</h2>
      Le Prestataire garantit, conformément aux dispositions légales et sans
      paiement complémentaire, le Client, contre tout défaut de conformité ou
      vice caché, provenant d’un défaut de réalisation des Services réservés et
      effectivement payés dans les conditions et selon les modalités définies
      aux présentes Conditions Générales de Vente.
      <br></br>Les Services fournis par l’intermédiaire du site Internet de
      l’Hôtel sont conformes à la réglementation en vigueur en Algérie. La
      responsabilité du Prestataire ne saurait être engagée en cas de
      non-respect de la législation du pays dans lequel les Services sont
      fournis, qu’il appartient au Client, qui est seul responsable du choix des
      Services demandés, de vérifier.
      <h2>ARTICLE 7 – Informatiques et Libertés</h2>
      Il est rappelé que les données nominatives qui sont demandés au Client
      sont nécessaires au traitement de sa réservation et à l’établissement des
      factures, notamment.
      <br></br>Ces données sont traitées et destinées à l’Hôtel et peuvent être
      communiquées à ses éventuels partenaires chargés de l’exécution, du
      traitement, de la gestion et du paiement des réservations ainsi que du
      séjour du Client.
      <br></br>Par ailleurs, l’Hôtel est susceptible d’adresser par courrier
      électronique à ses clients sa lettre d’informations, des offres
      promotionnelles, un questionnaire de satisfaction suite à son séjour
      hôtelier.
      <br></br>Le traitement des informations communiquées par l’intermédiaire
      du site internet de l’Hôtel a fait l’objet d’une déclaration auprès de la
      CNIL.
      <br></br>Le Client dispose, conformément aux réglementations nationales et
      européennes en vigueur d’un droit d’accès permanent, de modification, de
      rectification et d’opposition s’agissant des informations le concernant.
      <br></br>Ce droit peut être exercé dans les conditions et selon les
      modalités définies sur le site internet de l’Hôtel.
      <h2>ARTICLE 8 – Force majeure</h2>
      Les Parties ne pourront être tenues pour responsables si la non-exécution
      ou le retard dans l’exécution de l’une quelconque de leurs obligations,
      telles que décrites dans les présentes découle d’un cas de force majeure.
      <h2>Article 12 : DISPOSITIONS DIVERSES</h2>
      Les présentes Conditions générales de ventes, la Charte des Données
      personnelles, les Conditions de vente du tarif réservé par le Client, la
      Demande de réservation, la Confirmation de réservation par le Client,
      constituent l’intégralité de l’accord des parties dans la limite de son
      objet. Ils remplacent et annulent, en conséquence, dans cette limite, tout
      accord verbal ou écrit qui leur serait antérieur.
      <br></br>Aucune tolérance, quelle qu’en soit la nature, l’ampleur, la
      durée ou la fréquence, ne pourra être considérée comme créatrice d’un
      quelconque droit et ne pourra conduire à limiter d’une quelconque manière
      que ce soit, la possibilité d’invoquer chacune des clauses des présentes
      Conditions Générales de Vente, à tout moment, sans aucune restriction.
      <br></br>Toute clause des présentes Conditions Générales de Vente qui
      viendrait à être déclarée nulle ou illicite par un juge compétent serait
      privée d’effet, mais sa nullité ne saurait porter atteinte aux autres
      stipulations, ni affecter la validité des Conditions Générales de Vente
      dans leur ensemble ou leurs effets juridiques.
      <h2>ARTICLE 10 – Droit applicable – Langue</h2>
      Les présentes Conditions Générales de Vente et les opérations qui en
      découlent sont régies et soumises au droit Algerien.
      <br></br>Les présentes Conditions Générales de Vente sont rédigées en
      langue française. Dans le cas où elles seraient traduites en une ou
      plusieurs langues étrangères, seul le texte français ferait foi en cas de
      litige.
      <h2>ARTICLE 11 – Litiges</h2>
      Tous les litiges auxquels les opérations d’achat et de vente conclues en
      application des présentes conditions générales de vente pourraient donner
      lieu, concernant tant leur validité, leur interprétation, leur exécution,
      leur résiliation, leurs conséquences et leurs suites et qui n’auraient pu
      être résolues entre l’Hôtel et le Client seront soumis aux tribunaux
      compétents dans les conditions de droit commun. ARTICLE 12 – Information
      précontractuelle – Acceptation du Client Le Client reconnaît avoir eu
      communication, préalablement à la passation de sa réservation et à la
      conclusion du contrat, d’une manière lisible et compréhensible, des
      présentes Conditions Générales de Vente et de toutes les informations et
      notamment les informations suivantes :<br></br>● les caractéristiques
      essentielles des Services, compte tenu du support de communication utilisé
      et du Service concerné ;<br></br>● le prix des Services et des frais
      annexes ;<br></br>● en l’absence d’exécution immédiate du contrat, la date
      ou le délai auquel le Prestataire s’engage à fournir les Services réservés
      ;<br></br>● les informations relatives à l’identité du Prestataire, à ses
      coordonnées postales, téléphoniques et électroniques, et à ses activités,
      si elles ne ressortent pas du contexte,
      <br></br>● les informations relatives aux garanties légales et
      contractuelles et à leurs modalités de mise en œuvre ;<br></br>● les
      fonctionnalités du contenu numérique et, le cas échéant, à son
      interopérabilité ;<br></br>● la possibilité de recourir à une médiation
      conventionnelle en cas de litige ;<br></br>● les informations relatives
      aux conditions contractuelles importantes.
      <br></br>● les moyens de paiement acceptés.
      <br></br>Le fait pour une personne physique (ou morale), de réserver sur
      le site Internet de l’Hôtel emporte adhésion et acceptation pleine et
      entière des présentes Conditions Générales de Vente et obligation au
      paiement des Services commandés, ce qui est expressément reconnu par le
      Client, qui renonce, notamment, à se prévaloir de tout document
      contradictoire, qui serait inopposable au Prestataire
    </div>
  );
};

export default Policy;
